<template>
  <header v-if="User != null" class="header py-3 sm:px-8 px-4 sm:ml-20 ml-44"
    :class="clicked ? 'sm:ml-20 ml-44' : route == 'Speciality&Year' ? '' : 'sm:ml-64 ml-44'">
    <div class="header-content flex items-center justify-between flex-row">
      <div class="inline-flex items-center gap-3">
        <button class="menu-mobile-toggle sm:hidden block" v-show="(clicked === true) && (route != 'Speciality&Year')"
          @click="showSidebar">
          <HamburgerIcon />
        </button>
        <img v-show="route == 'Speciality&Year'" class="h-8 w-auto sm:h-10 sm:block hidden"
          src="../../assets/mystidia.png" />
        <button v-show="(clicked === false) && (route != 'Speciality&Year')" @click="closeSidebar" class="toggle-btn"
          :class="clicked === false ? 'left-64' : ''">
          <!--<svg  :fill="(dark && User.role =='etudiant') ? '#fff' : '#374151'" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="28px" height="28px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>-->
          <LeftIcon />
        </button>
        <button v-show="(clicked === true) && (route != 'Speciality&Year')" @click="showSidebar"
          class="toggle-btn right" :class="clicked === true ? 'left-20' : ''">
          <!--<svg  :fill="(dark && User.role =='etudiant') ? '#fff' : '#374151'" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="28px" height="28px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>-->
          <RightIcon />
        </button>
        <h1 :class="(dark && User.role == 'etudiant') ? 'text-white' : 'text-gray-700'" class="navbar-title">{{ title }}
        </h1>
      </div>

      <div class="flex justify-center items-center gap-2">
        <div class="flex gap-4 items-center">
          <router-link v-show="(route != 'Speciality&Year') && User.role != 'admin'" to="/specialityear"
            class="inline-flex justify-center w-auto rounded-md px-2 py-2 text-sm leading-5 font-medium text-gray-500 category-btn">
            <CategoryIcon />
          </router-link>
          <div v-if="User.role == 'etudiant'" class="notification-box" v-click-away="closeNotifications">
            <div class="rounded bg-white notification-btn px-2 py-2" @click="toggleNotifications()">
              <Notification />
              <div v-if="unreadNotifications > 0">
                <span>{{ unreadNotifications }}</span>
              </div>
            </div>
            <div class="notification-dropdown" v-show="openNotifications">
              <div v-if="notifications.length > 0" class="flex flex-col h-full">
                <div class="notifications-wrapper">
                  <div class="notification-item" v-for="(item, index) in notifications" :key="index"
                    @click="markNotificationAsRead(item.id, item.read_at)">
                    <router-link :to="{ name: 'NotificationDetails', params: { id: item.id } }">
                      <img src="/avatar_profile.jpg" alt="" class="notification-item__avatar">
                      <div class="notification-item__info">
                        <div class="notification-item__info-title">
                          <p>{{ item.title }}</p>
                        </div>
                        <div class="notification-item__info-date">
                          <p>{{ item.formatted_date }}</p>
                        </div>
                      </div>
                      <div class="seen-status" :class="item.read_at == null ? 'unread' : 'read'">
                        <span></span>
                      </div>
                    </router-link>

                  </div>
                </div>
                <div class="text-center p-2 mt-auto">
                  <router-link :to="{name: 'Notifications'}">Voir tout</router-link>
                </div>
              </div>
              <div v-else>
                <p class="font-medium text-center mt-3">Pas de notifications</p>
              </div>
            </div>
          </div>
        </div>

        <div v-click-away="onClickAway" class="relative inline-block text-left">
          <div>
            <span class="rounded-md border-black shadow-sm">
              <button @click="openItems = !openItems" type="button" class="
                  dropdown-btn 
                " id="options-menu" aria-haspopup="true" aria-expanded="true">
                <img :src="profil_image" class="w-12 h-12 rounded-full" />
                <div class="sm:block hidden">
                  <p v-if="User">{{ User.lastname }} {{ User.firstname }}</p>
                </div>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                  v-if="!openItems">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.0009 13.9478L7.46527 9.44998C7.12914 9.11708 6.58702 9.11708 6.25089 9.44998C6.09032 9.60861 6 9.82497 6 10.0506C6 10.2763 6.09032 10.4927 6.25089 10.6513L11.3928 15.7498C11.7286 16.0837 12.2714 16.0837 12.6072 15.7498L17.7491 10.6515C17.9097 10.4927 18 10.2763 18 10.0506C18 9.82497 17.9097 9.60861 17.7491 9.44977C17.4129 9.11708 16.8708 9.11708 16.5347 9.45019L12.0009 13.9478Z"
                    fill="currentColor" fill-opacity="0.48" />
                </svg>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.0009 10.0522L7.46527 14.55C7.12914 14.8829 6.58702 14.8829 6.25089 14.55C6.09032 14.3914 6 14.175 6 13.9494C6 13.7237 6.09032 13.5073 6.25089 13.3487L11.3928 8.25022C11.7286 7.91626 12.2714 7.91626 12.6072 8.25022L17.7491 13.3485C17.9097 13.5073 18 13.7237 18 13.9494C18 14.175 17.9097 14.3914 17.7491 14.5502C17.4129 14.8829 16.8708 14.8829 16.5347 14.5498L12.0009 10.0522Z"
                    fill="currentColor" fill-opacity="0.48" />
                </svg>
              </button>
            </span>
          </div>

          <div :class="openItems ? 'block' : 'hidden'" class="
              z-50
              origin-top-right
              absolute
              right-0
              w-full
              shadow-lg
              dropdown-items-wrapper
            ">

            <div class="dropdown-item-header" @click="openItems = !openItems">
              <!--<div class="ml-4 mt-3 text-gray-700 sm:text-base text-xs">
                <p v-if="User">{{ User.lastname }} {{ User.firstname }}</p>
              </div>
              <div class="ml-4 w-full mb-3 sm:text-sm text-gray-700 text-xs" v-if="User">
                {{ User.email }}
              </div>-->
              <img :src="profil_image" class="w-12 h-12 rounded-full" />
              <div class="sm:block">
                <p v-if="User">{{ User.lastname }} {{ User.firstname }}</p>
              </div>

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="sm:block hidden">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.0009 10.0522L7.46527 14.55C7.12914 14.8829 6.58702 14.8829 6.25089 14.55C6.09032 14.3914 6 14.175 6 13.9494C6 13.7237 6.09032 13.5073 6.25089 13.3487L11.3928 8.25022C11.7286 7.91626 12.2714 7.91626 12.6072 8.25022L17.7491 13.3485C17.9097 13.5073 18 13.7237 18 13.9494C18 14.175 17.9097 14.3914 17.7491 14.5502C17.4129 14.8829 16.8708 14.8829 16.5347 14.5498L12.0009 10.0522Z"
                  fill="currentColor" fill-opacity="0.48" />
              </svg>
            </div>
            <!--<div class="border-t border-gray-200"></div>-->

            <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <router-link to="/dashboard" class="
                  flex flex-row
                  dropdown-item
                " v-show="route != 'Speciality&Year'">
                <span class="
                    flex
                    items-center
                    justify-center
                  ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_197_17153)">
                      <path d="M15 22V20" stroke="#F87171" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M18 22V18" stroke="#F87171" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M21 22V16" stroke="#F87171" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M19 12.494V12H21L12 3L3 12H5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H11"
                        stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M9 21V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H13C13.5304 13 14.0391 13.2107 14.4142 13.5858C14.7893 13.9609 15 14.4696 15 15V15.5"
                        stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_197_17153">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                </span>
                <span role="menuitem" class="flex items-center">Tableau de bord</span>
              </router-link>
              <div class="dropdown-divider" v-show="route != 'Speciality&Year'"></div>
              <router-link to="/dashboard/account" class="
                  flex flex-row
                  dropdown-item
                " v-show="route != 'Speciality&Year'">
                <span class="
                    flex
                    items-center
                    justify-center
                  ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_197_17157)">
                      <path
                        d="M10.325 4.317C10.751 2.561 13.249 2.561 13.675 4.317C13.7389 4.5808 13.8642 4.82578 14.0407 5.032C14.2172 5.23822 14.4399 5.39985 14.6907 5.50375C14.9414 5.60764 15.2132 5.65085 15.4838 5.62987C15.7544 5.60889 16.0162 5.5243 16.248 5.383C17.791 4.443 19.558 6.209 18.618 7.753C18.4769 7.98466 18.3924 8.24634 18.3715 8.51677C18.3506 8.78721 18.3938 9.05877 18.4975 9.30938C18.6013 9.55999 18.7627 9.78258 18.9687 9.95905C19.1747 10.1355 19.4194 10.2609 19.683 10.325C21.439 10.751 21.439 13.249 19.683 13.675C19.4192 13.7389 19.1742 13.8642 18.968 14.0407C18.7618 14.2172 18.6001 14.4399 18.4963 14.6907C18.3924 14.9414 18.3491 15.2132 18.3701 15.4838C18.3911 15.7544 18.4757 16.0162 18.617 16.248C19.557 17.791 17.791 19.558 16.247 18.618C16.0153 18.4769 15.7537 18.3924 15.4832 18.3715C15.2128 18.3506 14.9412 18.3938 14.6906 18.4975C14.44 18.6013 14.2174 18.7627 14.0409 18.9687C13.8645 19.1747 13.7391 19.4194 13.675 19.683C13.249 21.439 10.751 21.439 10.325 19.683C10.2611 19.4192 10.1358 19.1742 9.95929 18.968C9.7828 18.7618 9.56011 18.6001 9.30935 18.4963C9.05859 18.3924 8.78683 18.3491 8.51621 18.3701C8.24559 18.3911 7.98375 18.4757 7.752 18.617C6.209 19.557 4.442 17.791 5.382 16.247C5.5231 16.0153 5.60755 15.7537 5.62848 15.4832C5.64942 15.2128 5.60624 14.9412 5.50247 14.6906C5.3987 14.44 5.23726 14.2174 5.03127 14.0409C4.82529 13.8645 4.58056 13.7391 4.317 13.675C2.561 13.249 2.561 10.751 4.317 10.325C4.5808 10.2611 4.82578 10.1358 5.032 9.95929C5.23822 9.7828 5.39985 9.56011 5.50375 9.30935C5.60764 9.05859 5.65085 8.78683 5.62987 8.51621C5.60889 8.24559 5.5243 7.98375 5.383 7.752C4.443 6.209 6.209 4.442 7.753 5.382C8.753 5.99 10.049 5.452 10.325 4.317Z"
                        stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12Z"
                        stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_197_17157">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span role="menuitem" class="flex items-center">Gérer le compte</span>
              </router-link>
              <div class="dropdown-divider" v-show="route != 'Speciality&Year'"></div>
              <button v-if="route != 'Speciality&Year'" @click="logout" class="
                  flex
                  w-full
                  flex-row
                  dropdown-item
                " role="menuitem">
                <span class="
                    flex
                    items-center
                    justify-center
                  ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_197_17161)">
                      <path
                        d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16"
                        stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M7 12H21M21 12L18 9M21 12L18 15" stroke="#F87171" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_197_17161">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span class="flex items-center">Déconnexion </span>
              </button>
              <button v-else @click="logoutsp" class="
                  flex
                  w-full
                  flex-row
                  px-4
                  py-2
                  sm:text-sm
                  leading-5
                  text-gray-700
                  hover:bg-gray-100
                  hover:text-gray-900
                  focus:outline-none
                  focus:bg-gray-100
                  focus:text-gray-900
                  text-xs
                " role="menuitem">
                <span class="
                    flex
                    items-center
                    justify-center
                    w-4
                    h-4
                    mx-1
                    text-red-400
                    sm:text-lg text-xs
                  ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_197_17161)">
                      <path
                        d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16"
                        stroke="#F87171" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M7 12H21M21 12L18 9M21 12L18 15" stroke="#F87171" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_197_17161">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span class="flex items-center">Déconnexion</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CategoryIcon from '../icons/CategoryIcon.vue';
import HamburgerIcon from '../icons/HamburgerIcon.vue';
import LeftIcon from '../icons/LeftIcon.vue';
import Notification from '../icons/Notification.vue';
import RightIcon from '../icons/RightIcon.vue';
import notificationRequests from '../../services/requests/notification';
export default {

  components: {
    CategoryIcon,
    LeftIcon,
    RightIcon,
    HamburgerIcon,
    Notification
  },
  data() {
    return {
      openItems: false,
      User: null,
      profil_image: null,
      openNotifications: false,
      notifications: []
    };
  },

  props: {
    logout: Function,
    logoutsp: Function,
    title: String,
    dark: Boolean,
    route: String
  },

  mounted() {
    if (this.$store.getters.get_user.length != 0) {
      this.User = this.$store.getters.get_user;
    }
    if (this.$store.getters.get_image != null) {
      this.profil_image = this.$store.getters.get_image;
    } else {
      this.profil_image = "/avatar_profile.jpg";
    }

    this.eventBus.on("EventUser", (data) => {
      this.User = data;
    });

    this.eventBus.on("EventPhoto", (data) => {
      this.profil_image = data;
    });

    this.getNotifications();
  },
  computed: {
    clicked() {
      return this.$store.getters.get_clicked;
    },
    User() {
      return this.$store.getters.get_user;
    },
    unreadNotifications() {
      const notifications = this.notifications.filter((item) => item.seen_state == 'UNSEEN');
      return notifications.length;
    },
  },

  methods: {
    onClickAway() {
      this.openItems = false;
    },
    showSidebar() {
      this.$store.commit("set_clicked", false);
    },
    closeSidebar() {
      this.$store.commit("set_clicked", true);
    },
    closeNotifications() {
      this.openNotifications = false;
    },
    toggleNotifications() {
      this.openNotifications = !this.openNotifications;
      this.seenState();
    },
    async getNotifications() {
      try {
        const response = await notificationRequests.getNotifications(1);
        if (response) {
          this.notifications = response.data.data;
        }
      } catch (error) {

      }
    },

    async seenState() {
      if (this.unreadNotifications > 0) {
        try {
          const response = await notificationRequests.seenState();
          if (response.data.success) {
            this.getNotifications();
          }
        } catch (error) {

        }
      }
    },

    async markNotificationAsRead(id, read_at) {
      if (read_at == null) {
        try {
          const response = await notificationRequests.markNotificationAsRead(id);
          if (response) {
            this.getNotifications();
            this.openNotifications = false;
          }
        } catch (error) {

        }

      } else {
        this.openNotifications = false;
      }
    },
  },
};
</script>

<style></style>